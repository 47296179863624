<template>
  <div>
    <div class="greyBackground"></div>
    <v-card class="greyColor" elevation="0">
      <h2 class="faledia-caption">Stellenwert und Zahlenwert</h2>
      <v-card outlined>
        <v-card-title>Was sollen Schüler:innen lernen?</v-card-title>
        <v-card-text>
          Die Kinder können...
          <ul>
            <li>
              erläutern, dass die Position einer Ziffer innerhalb einer Zahl
              ihren Stellenwert bestimmt.
            </li>
            <li>
              das Ansteigen der Bündelungseinheiten von recht nach links
              (beispielgebunden) beschreiben.
            </li>
            <li>
              erläutern, dass der Wert einer Ziffer an einer bestimmten Position
              die Anzahl der entsprechenden Bündel des zugehörigen Stellenwerts
              angibt.
            </li>
          </ul>
        </v-card-text>
      </v-card>

      <br />

      <h3 class="faledia-caption">
        Was sollten Sie zum Prinzip des Stellenwertes und des Zahlenwertes
        wissen?
      </h3>

      <p>
        Das Prinzip des Stellenwerts meint, dass die Position der Ziffer ihren
        Stellenwert bestimmt, wobei die Bündelungseinheiten von rechts nach
        links ansteigen. Das heißt, die Stellung der Ziffer innerhalb des
        Zahlwortes gibt die Mächtigkeit der zugehörigen Bündel an, also aus wie
        vielen Elementen ein Bündel an der entsprechenden Stelle besteht
        (Stellenwert der Ziffer). Hier kommt das Prinzip des Zahlenwerts ins
        Spiel. Der Zahlenwert der Ziffer gibt die Anzahl der Bündel des
        jeweiligen Stellenwerts an (vgl. Padberg & Benz 2011, 82). Nur durch das
        Zusammenspiel dieser beiden Prinzipien kommen wir tatsächlich mit den
        zehn Ziffern von 0 bis 9 für die Darstellung einer beliebig großen Zahl
        aus. Zum Verständnis der Konventionen ist außerdem die Einsicht in die
        Zerlegbarkeit von Zahlen (Teil-Teil-Ganzes-Beziehung, additive
        Eigenschaft von Stellenwertsystemen) wichtig: 23 ist dasselbe wie 20 und
        3 oder 2 Z(ehner) und 3 E(iner) oder 3 und 20.
      </p>

      <p>
        Der Ziffer Null kommt eine besondere Bedeutung zu, denn ein nicht
        besetzter (kleinerer) Stellenwert muss mit der Ziffer Null
        gekennzeichnet und darf nicht einfach weggelassen werden: Beispielsweise
        ist 467 eine ganz andere Zahl als 40607. Das bedeutet also, dass vom
        höchsten Stellenwert ausgehend, jede niedrigere Stelle besetzt sein
        muss, gegebenenfalls mit einer Null.
      </p>

      <p>
        Für uns Erwachsene ist die Nutzung dieser beiden Prinzipien ganz
        selbstverständlich. Lernen Kinder das Zahlsystem kennen, ist dies für
        sie jedoch zunächst ganz neu und fordert sie enorm. Auch hier sollen Sie
        sich noch einmal in die Situation der Kinder hineinversetzen und Zahlen
        in anderen Stellenwertsystemen ordnen.
      </p>

      <div style="background-color: white">
        <LernbausteinSortieren
          :id="LBSTSortieren[0].id"
          :workOrder="LBSTSortieren[0].workOrder"
          :elements="LBSTSortieren[0].elements"
          :responses="LBSTSortieren[0].responses"
          :left="LBSTSortieren[0].left"
          :right="LBSTSortieren[0].right"
        >
          <template v-slot:alternative-content>
            <video
              preload="auto"
              controls
              width="60%"
              style="margin-left: 20%"
              @play="
                videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
              "
              @fullscreenchange="
                videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
              "
              @webkitfullscreenchange="
                videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
              "
              @mozfullscreenchange="
                videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
              "
              @msfullscreenchange="
                videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
              "
              @ended="
                videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
              "
              @pause="
                videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
              "
              @seeking="
                videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
              "
              @enterpictureinpicture="
                videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
              "
              @leavepictureinpicture="
                videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
              "
            >
              <source
                src="@/assets/swvi/SWV-I_Zahlen_sortieren_201113.mp4"
                type="video/mp4"
              />
            </video>
          </template>
        </LernbausteinSortieren>
      </div>

      <p>
        Das Ordnen von Zahlen entsprechend ihrer Größe ist für uns im
        Zehnersystem eine Routinetätigkeit. Müssen wir dies bei Zahlen in
        anderen Stellenwertsystemen nachvollziehen, wird uns schnell deutlich,
        wie komplex das Prinzip des Stellenwerts und das Prinzip des Zahlenwerts
        sowie ihr Zusammenspiel sind. Die Prinzipien des Stellenwerts und
        Zahlwerts zu verstehen, zu verinnerlichen und im Zehnersystem
        anzuwenden, stellt Kinder vor eine große Herausforderung.
      </p>

      <br />

      <AppLiteraturSWV />
    </v-card>
    <AppBottomNavSWV
      back="/stellenwertverstaendnis/hintergrundwissen/buendeln-und-entbuendeln"
      next="/stellenwertverstaendnis/hintergrundwissen/darstellungswechsel"
    />
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";
import AppLiteraturSWV from "@/common/AppLiteraturSWV";
import AppBottomNavSWV from "@/common/AppBottomNavSWV";
import LernbausteinSortieren from "@/components/faledia/explorativeElemente/LernbausteinSortieren";
import SSZH_2_1 from "@/components/faledia/seiten/swve/beispiele/SSZH_2_1";
export default {
  components: {
    AppLiteraturSWV,
    AppBottomNavSWV,
    LernbausteinSortieren,
  },
  data: () => ({
    LBSTSortieren: [SSZH_2_1],
  }),
  methods: {
    videoPlayerEvent(event, name) {
      AppHelper.videoPlayerEvent(this, event, name);
    },
  },
};
</script>

<style scoped></style>
